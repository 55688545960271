<template>
  <div>
    <the-app-bar />
    <view-purchased-pack />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
import ViewPurchasedPack from "../../../components/PurchasedPack/viewPurchasedPack.vue";
export default {
  components: { TheAppBar, Footer, ViewPurchasedPack }
};
</script>

<style lang="scss" scoped></style>

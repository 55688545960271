<template>
  <div class="font-montserrat top">
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          FoodHub Purchased Packages
        </h4>
      </div>
    </div>
    <div v-if="loading" class="row py-5 justify-content-center">
      <fade-loader :loading="loading"></fade-loader>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8 mx-auto">
          <div class="my-4">
            <div class="row d-flex justify-content-center">
              <div class="col-md-4">
                <p @click="getPersonalPacks" class="cursor-pointer btn btn-outline-info"
                  :class="{ active: showactiveTab === 'personal' }">
                  Personal Packs
                </p>
              </div>
              <div class="col-md-4">
                <p @click="getOtherPacks" class="cursor-pointer btn btn-outline-info"
                  :class="{ active: showactiveTab === 'others' }">Other Packs</p>
              </div>
            </div>
            <hr class="my-4" />
            <div class="row mb-4 d-flex justify-content-center" v-if="showpersonalpacks">
              <div class="col-md-4">
                <button type="button" :class="{ active: activeTab === 'tab1' }" @click="getPersonalNewPacks(tab1)"
                  class="btn btn-outline-primary">
                  New Purchased Pack
                </button>
              </div>
              <div class="col-md-4">
                <button type="button" :class="{ active: activeTab === 'tab2' }" @click="getPersonalOngoingPacks(tab2)"
                  class="btn btn-outline-danger">
                  Used Purchased Pack
                </button>
              </div>

              <div class="col-md-4">
                <button type="button" :class="{ active: activeTab === 'tab3' }" @click="getPersonalExpiredPacks(tab3)"
                  class="btn btn-outline-success">
                  Expired Purchased Pack
                </button>
              </div>
            </div>
            <div class="row mb-4 d-flex justify-content-center" v-else>
              <div class="col-md-4">
                <button type="button" :class="{ active: activeTab === 'tab1' }" @click="getOtherNewPacks"
                  class="btn btn-outline-primary">
                  New Purchased Pack
                </button>
              </div>
              <div class="col-md-4">
                <button type="button" :class="{ active: activeTab === 'tab2' }" @click="getOtherOngoingPacks"
                  class="btn btn-outline-danger">
                  Ongoing Purchased Pack
                </button>
              </div>

              <div class="col-md-4">
                <button type="button" :class="{ active: activeTab === 'tab3' }" @click="getOtherExpiredPacks"
                  class="btn btn-outline-success">
                  Expired Purchased Pack
                </button>
              </div>
            </div>
            <div v-for="item in packs" :key="item.id" class="list-group mb-5 shadow">
              <router-link :to="`/foodhub-purchased-packs-detail/${item.guid}`" class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                   
                    <strong class="mb-0">{{ currency }} {{ item.amount }}</strong>
                    <p class="text-muted mb-0">
                      {{ item.packageExpirationQuantityUnit }} pack -
                      {{ item.packageSubCategory }}
                    </p>
                    <p class="text-muted mb-0">
                      {{ item.packageQuantity }} Pack(s)
                    </p>
                    <p class=" text-danger mb-0">
                      Expires: {{ item.expiryDate | formatDate() }}
                    </p>
                  </div>
                  <div v-if="showusebtn" class="col-auto">
                    <button @click.prevent="usePack(item.guid)" class="btn btn-primary">
                      Use Pack
                    </button>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-spaces-and-tabs */
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeTab: "tab1",
      loading: false,
      color: "#0b4369",
      pack: "foodh",
      packs: [],
      responses: [],
      currency: JSON.parse(localStorage.getItem("account_info")).countryCurrency,
      showpersonalpacks: true,
      showusebtn: true,
      showactiveTab:'personal'
    
    };
  },

  components: {},

  computed: {
    ...mapGetters("foodhub", ["supercategories"])
  },

  mounted() {
    localStorage.removeItem("BeneficiaryTypeId")
    localStorage.removeItem("packageSubCategoryId")
    localStorage.removeItem("purchase_for")
    localStorage.removeItem("beneficiaryDetail")
    localStorage.removeItem("BeneficiaryPhoneNumber")
    localStorage.removeItem("latitude")
    localStorage.removeItem("longitude")
    localStorage.removeItem("autocomplete")
    localStorage.removeItem("address")

    

    this.$store.dispatch("foodhub/getFoodHubClientPurchases").then((res) => {
      if (res.status == 200) {
        // localStorage.setItem('showusebtn', true)
        this.$store.dispatch("default/setShowbtn",true)
        this.responses = res.data;
        this.packs = res.data.filter(
          (el) =>
            el.beneficiaryId ==
              JSON.parse(localStorage.getItem("account_info")).id &&
            el.packageUsageStatusId == 0
        );
      }
    });

    let tab = localStorage.getItem("activeTab") || 3
    if (tab == 3) {
      this.getPersonalPacks()
    } else {
      this.getOtherPacks()
    }
  },
  methods: {
    usePack(packageGUID) {
      this.loading = true;
      this.$store
        .dispatch("foodhub/usePackage", packageGUID)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.$swal({
              icon: "success",
              title: "Success",
              text: res.data
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },
    getPersonalPacks() {
      this.showactiveTab = "personal"
      this.$store.dispatch("default/setShowbtn", true)
      this.showusebtn = true;
      this.showpersonalpacks = true;
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId ==
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 0
      );
    },

    getOtherPacks() {
      this.showactiveTab = "others"
      this.$store.dispatch("default/setShowbtn", false)
      this.showpersonalpacks = false;
      this.showusebtn = false;
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId !=
          JSON.parse(localStorage.getItem("account_info")).id
      );
    },
    getPersonalNewPacks(tab) {
      this.activeTab = "tab1"
      this.$store.dispatch("default/setShowbtn", false)
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId ==
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 0
      );
    },
    getPersonalOngoingPacks(tab) {
      this.activeTab = "tab2"
      this.$store.dispatch("default/setShowbtn", false)
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId ==
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 1
      );
    },
    getPersonalExpiredPacks(tab) {
      this.activeTab = "tab3"
      this.$store.dispatch("default/setShowbtn", false)
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId ==
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 2
      );
    },

    //Other packs buttons
    getOtherNewPacks() {
      this.activeTab = "tab1"
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId !=
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 0
      );
    },
    getOtherOngoingPacks() {
      this.activeTab = "tab2"
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId !=
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 1
      );
    },
    getOtherExpiredPacks() {
      this.activeTab = "tab3"
      this.packs = this.responses.filter(
        (el) =>
          el.beneficiaryId !=
            JSON.parse(localStorage.getItem("account_info")).id &&
          el.packageUsageStatusId == 2
      );
    }
  }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
}

img {
  width: 100%; /* You can set the dimensions to whatever you want */
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
